// This import must go at the top
import '../api/http-setup';

import '@mentimeter/ragnar-tailwind-config/globals.css';
import * as React from 'react';
import NextApp from 'next/app';
import { RendererProvider as FelaProvider } from '@mentimeter/ragnar-react';
import type { AppProps, AppContext } from 'next/app';
import {
  setUser as setSentryUser,
  setTag as setSentryTag,
} from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { PublicEnvVars } from '@mentimeter/next-env-vars';
import createRenderer from '../renderer';
import AppProvider from '../features/App';
import NoScript from '../features/NoScript';
import { SplitProvider } from '../split.io';
import { getIdentifier } from '../helpers/identifier';
import { TrackingProvider } from '../helpers/tracker';

const isServer = typeof window === 'undefined';

if (!isServer) {
  getIdentifier().then((id) => setSentryUser(id ? { id } : null));
}

interface Props extends AppProps {
  renderer: any;
}

const clientRenderer = isServer ? null : createRenderer();

function VotingApp({ Component, pageProps, renderer }: Props) {
  const { pathname } = useRouter();
  React.useEffect(() => {
    /* the pathname is e.g. /app/presentation/[...slug]. This is more useful for grouping than the url, e.g. /app/presentation/abc/def */
    setSentryTag('nextJsPathname', pathname);
  }, [pathname]);
  React.useEffect(() => {
    /* This is to indicate to e2e tests that React has rendered on the clientside, and the app is ready to interact with */
    window.REACT_HAS_HYDRATED = true;
    window.dispatchEvent(new CustomEvent('REACT_HAS_HYDRATED'));
  }, []);
  return (
    <>
      <PublicEnvVars />
      <FelaProvider renderer={renderer || clientRenderer}>
        <AppProvider>
          <NoScript />
          <TrackingProvider>
            <SplitProvider>
              <Component {...pageProps} />
            </SplitProvider>
          </TrackingProvider>
        </AppProvider>
      </FelaProvider>
    </>
  );
}

export default VotingApp;

// This disables Automatic Static Optimization. This is needed so that values do not
// get "burnt into" the app on stage, and then promoted to prod with stage values.
VotingApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};
