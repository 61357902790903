import { useSearchParams } from 'next/navigation';
import * as React from 'react';
import {
  getIntegration,
  isInternalIntegration,
  type RegisteredIntegrations,
} from './helpers';

export interface IntegrationContextValueT {
  integrationId: RegisteredIntegrations | null;
  isInternalIntegration: boolean;
}

const IntegrationContext = React.createContext<IntegrationContextValueT>({
  integrationId: null,
  isInternalIntegration: false,
});
IntegrationContext.displayName = 'IntegrationContext';

export function IntegrationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const integration = useSearchParams()?.get('integration');
  const integrationId = getIntegration((integration || '').toString());
  // Set it once as surveys will rewrite the URL with removes
  // any params passed
  const { current: value } = React.useRef({
    integrationId,
    isInternalIntegration: isInternalIntegration(integrationId),
  });

  return (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  );
}

export function useIntegration(): IntegrationContextValueT {
  return React.useContext(IntegrationContext);
}
