import {
  createRenderer as felaCreateRenderer,
  prefixer,
  fallback,
  normalizer,
} from '@mentimeter/ragnar-react';
import * as Fonts from '@mentimeter/ragnar-fonts';
import { reset, setup } from '@mentimeter/ragnar-reset';

// Configure Design System Renderer
function createRenderer(): any {
  const renderer = felaCreateRenderer({
    plugins: [prefixer(), fallback(), normalizer()],
    selectorPrefix: 'm-',
  });

  renderer.renderFont(
    'MentiText',
    Fonts.MentiText.regular.files,
    Fonts.MentiText.regular.style,
  );
  renderer.renderFont(
    'MentiText',
    Fonts.MentiText.semiBold.files,
    Fonts.MentiText.semiBold.style,
  );
  renderer.renderFont(
    'MentiDisplay',
    Fonts.MentiDisplay.regular.files,
    Fonts.MentiDisplay.regular.style,
  );
  renderer.renderFont(
    'MentiDisplay',
    Fonts.MentiDisplay.semiBold.files,
    Fonts.MentiDisplay.semiBold.style,
  );

  renderer.renderStatic(reset(setup('#__next', false)));
  return renderer;
}

export default createRenderer;
