'use client';

import { useEffect } from 'react';

// Only allow www-web, but fallback to permit
// all targets if www-web url is not defined
const wwwUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_WWW_URL'];
const allowedMessageTargets = wwwUrl || '*';

const isTextInputOrTextArea = (event: KeyboardEvent) => {
  // These input types require use of keyboard presses, and so,
  // they should never be captured and broadcasted

  const TEXT_INPUT_TYPES = /TEXT|PASSWORD|EMAIL|NUMBER|SEARCH|TEL|URL/i;
  const { tagName, type: fieldType } = event.target as any;
  return (
    /TEXTAREA/i.test(tagName) ||
    (/INPUT/i.test(tagName) && TEXT_INPUT_TYPES.test(fieldType))
  );
};

const isModifierKeyPressed = (event: KeyboardEvent) =>
  event.metaKey || event.ctrlKey || event.altKey;

function broadcastEventsToParent(event: KeyboardEvent) {
  if (isTextInputOrTextArea(event) || isModifierKeyPressed(event)) return;

  // Key presses should be completely ignored in the voting-web frame
  event.preventDefault();

  const { key, type } = event;
  if (event.key) {
    window.parent.postMessage({ key, type }, allowedMessageTargets);
  }
}

export function usePostKeyboardEventMessageToParent({
  shouldBroadcast,
}: {
  shouldBroadcast: boolean;
}) {
  useEffect(() => {
    if (!shouldBroadcast) return;

    document.addEventListener('keydown', broadcastEventsToParent);
    document.addEventListener('keypress', broadcastEventsToParent);
    document.addEventListener('keyup', broadcastEventsToParent);
    return () => {
      document.removeEventListener('keydown', broadcastEventsToParent);
      document.removeEventListener('keypress', broadcastEventsToParent);
      document.removeEventListener('keyup', broadcastEventsToParent);
    };
  });
}
