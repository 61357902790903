'use client';

import * as React from 'react';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { themes } from '@mentimeter/ragnar-colors';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { VOTING_THEME_OVERRIDES } from '../constants/voting-theme-overrides';
import { usePostKeyboardEventMessageToParent } from '../helpers/usePostKeyboardEventMessageToParent';
import { isFacilitatorMode } from '../helpers/isFacilitatorMode';
import { isTryMode } from '../helpers/isTryMode';
import { CookieConsentProvider } from './cookie-consent/CookieConsent';
import { IntegrationProvider } from './integrations';

const breakpoints = designSystemConfig.breakpoints.map(stripAllNonNumericChars);

const App = ({ children }: { children: React.ReactNode }) => {
  // This prevents mobile browsers to double-tap-zoom and
  // similar. Pretty much what libs like FastClick does.
  React.useEffect(() => {
    const NOOP = () => {};
    document.body.addEventListener('touchstart', NOOP, false);
    return () => document.body.removeEventListener('touchstart', NOOP, false);
  }, []);

  // Ensures the presentation can pace even though the voting app is focused
  // use case: when voting is embedded in iframe, such as try mode.
  usePostKeyboardEventMessageToParent({
    shouldBroadcast: isTryMode() || isFacilitatorMode(),
  });

  return (
    <ThemeProvider
      theme={{
        ...designSystemConfig,
        ...themes.light,
        ...VOTING_THEME_OVERRIDES,
      }}
      themeClass="light"
    >
      <CookieConsentProvider>
        <IntegrationProvider>
          <DeviceProvider breakpoints={breakpoints}>{children}</DeviceProvider>
        </IntegrationProvider>
      </CookieConsentProvider>
    </ThemeProvider>
  );
};

export default App;
