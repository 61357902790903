import { isServer } from '@mentimeter/detect-server';

export type RegisteredIntegrations = 'Hopin' | 'MsTeams' | 'Zoom';

const integrationByName: Record<string, RegisteredIntegrations> = {
  hopin: 'Hopin',
  msteams: 'MsTeams',
  zoom: 'Zoom',
};

const isRegisteredIntegration = (
  string: string,
): RegisteredIntegrations | undefined => {
  return integrationByName[string];
};

// Passed the `integration` query param from next/router
export function getIntegration(
  integrationQueryParam: string,
): RegisteredIntegrations | null {
  if (isServer) return null;
  if (window?.navigator?.userAgent?.includes('Zoom')) {
    return 'Zoom';
  }

  const integration = isRegisteredIntegration(
    integrationQueryParam.toLowerCase(),
  );

  return integration || null;
}

export function isInternalIntegration(integrationId: string | null): boolean {
  if (!integrationId) return false;
  const integration = integrationId.toLowerCase();

  return integration === 'msteams' || integration === 'zoom';
}
